/* The popup chat - hidden by default */
.chat-popup {
  display: block;
  position: fixed;
  bottom: 0;
  right: 15px;
  width: 320px;
  z-index: 9;
}

/* Add styles to the form container */
.joinOuterContainer {
  padding: 10px;
  background-color: white;
  border: 3px solid #f1f1f1;
}

/* Full-width textarea */
.fjoinOuterContainer textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}
  // .joinOuterContainer {
  //   display: flex;
  //   justify-content: center;
  //   text-align: center;
  //   height: 100vh;
  //   align-items: center;
  //   background-color: #1A1A1D;
  // }
  
  .joinInnerContainer {
    // width: 20%;
  }
  
  .joinInput {
    border-radius: 2rem;
    padding: 0 20px;
    width: 100%;
  }
  
  .heading {
    color: white;
    font-size: 2.5em;
    padding-bottom: 10px;
    border-bottom: 2px solid white;
  }
  
  .button {
      color: #fff !important;
      text-transform: uppercase;
      text-decoration: none;
      background: #2979FF;
      padding: 20px;
      border-radius: 5px;
      display: inline-block;
      border: none;
      width: 100%;
  }
  .btn-position {
    margin: auto;
    width: 20%;

  .btn-chat {
    display: inline-block;
    border: 0.125rem solid #dfe9fb;
    border-radius: 2rem;
    background-color: #f6fdfd;
    color: #fff;
    font: 700 0.75rem/0 "Raleway", sans-serif;
    text-decoration: none;
    transition: all 0.2s;
    }
  }
  .mt-20 {
    margin-top: 20px;
  }
  .chatHeader {
    padding: 10px 15px 10px 15px !important;
    background-color: #fff;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    border: 3px solid #f1f1f1;
    border-bottom: none;
    // background: #039aea !important;
    // background: linear-gradient(-45deg, #039aea, #12c7f2) !important;
    #chatCloseIcon {
      margin: 8px 0 0 0;
      float: right;
      cursor: pointer;
    }
  }

  .card-header {
    
  }

  .chatHeaderText {
    // color: white;
    font-size: 15px;
    font-weight: bold;
    margin-left: 1rem;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .chat-popup {
      width: 100%;

    }
    .joinOuterContainer {
      height: auto;
    }
  
    .joinInnerContainer {
      width: auto;
    }
  /* The popup chat - hidden by default */
.chat-popup {
  position: sticky;
  overflow: hidden;
  bottom: 0;
  left: 0;
  // border: 3px solid #f1f1f1;
  z-index: 0;
}

/* Add styles to the form container */
.joinOuterContainer {
  max-width: 100%;
  padding: 10px;
  background-color: white;
}

/* Full-width textarea */
.fjoinOuterContainer textarea {
  width: 100%;
  padding: 15px;
  margin: 0px;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}
  
  }

  @media (max-width: 720px) and (min-width: 1200px) {
    .send {
      color: #fff !important;
      text-transform: uppercase;
      text-decoration: none;
      padding: 40px 0 40px 65px;
      display: inline-block;
      border-left: #e9ecef solid;
      width: 20%;
    }
  }
  button:focus {
    outline: 0;
  }