html {
  scroll-behavior: smooth;

}
.hideForNow {
  display: none;
}

.landing-container {
  margin: 0;
  display: grid;
  font-family: Helvetica, sans-serif;
  background-color: #f4f4f4;
}

a {
  color: #000;
}

/* header */

#header {
  background-color: #fff;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  z-index: 3;
  
}
@media (max-width: 991px) {
  section {
    padding: 75px 0 !important;
}
}


@media (min-width: 992px) {
  #home {
    background: url(../../assets/landing-page/header-background.jpg) center center no-repeat;
    background-size: cover;
}
}


.landing-logo {
  max-width: 100%;
  height: auto;
  padding-top: 15px;
  padding-bottom: 0;
  width: 220px;
}

#header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
  background-color: #fff;
}

#header li a {
  display: block;
  padding: 15px 15px 10px 15px;
  border-top: 1px solid #f4f4f4;
  text-decoration: none;
}

#header li a:hover,
#header .menu-btn:hover {
  background-color: #f4f4f4;
}

#header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}

/* menu */
.flex {
  display: flex;
  justify-content: space-between;
}

#header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .2s ease-out;
}

/* menu icon */

#header .menu-icon {
  cursor: pointer;
  display: inline-block;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

#header .menu-icon .navicon {
  background: #333;
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

#header .menu-icon .navicon:before,
#header .menu-icon .navicon:after {
  background: #333;
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

#header .menu-icon .navicon:before {
  top: 5px;
}

#header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

#header .menu-btn {
  display: none;
}

#header .menu-btn:checked~.menu {
  max-height: 100%;
}

#header .menu-btn:checked~.menu-icon .navicon {
  background: transparent;
}

#header .menu-btn:checked~.menu-icon .navicon:before {
  transform: rotate(-45deg);
}

#header .menu-btn:checked~.menu-icon .navicon:after {
  transform: rotate(45deg);
}

#header .menu-btn:checked~.menu-icon:not(.steps) .navicon:before,
#header .menu-btn:checked~.menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 54em) {
  #header li {
    float: left;
  }

  #header li a {
    padding: 20px 30px;
  }

  #header .menu {
    clear: none;
    float: right;
    max-height: none;
  }

  #header .menu-icon {
    display: none;
  }
}

//  -----------------------------------------------------------------------------------------------------------------
.title {
  font-size: 36px;
}
#home #header-content {
  // padding-top: 8rem;
  padding-bottom: 5rem;
  text-align: center;
}
.turquoise {
color: #00bfd8;
}
.home-container {
padding: 2rem;
}

.align-center {
text-align: center;
}
#home .text-container {
  margin-bottom: 4rem;
  text-align: center;
}
#home .text-container {
  margin-bottom: 4rem;
}
.btn-solid-lg {
  display: inline-block;
  padding: 1.375rem 2.625rem 1.375rem 2.625rem;
  border: 0.125rem solid #00bfd8;
  border-radius: 2rem;
  background-color: #00bfd8;
  color: #fff;
  font: 700 0.75rem/0 "Raleway", sans-serif;
  text-decoration: none;
  transition: all 0.2s;
  box-shadow: -5px -5px 20px #FFF, 5px 5px 20px #BABECC;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    background-color: #fff;
    color: #039aea;
    box-shadow: -2px -2px 5px #FFF, 2px 2px 5px #BABECC;
  }
  &:active {
    box-shadow: inset 1px 1px 2px #BABECC, inset -1px -1px 2px #FFF;
  }
}

@media (min-width: 1200px) {
  #home .text-container {
    margin-top: 5.375rem;
    margin-left: 1rem;
    margin-right: 2rem;
}
}

@media (min-width: 992px) {
  #home .text-container {
    margin-top: 10rem;
    margin-bottom: 0;
}
}
@media (max-width: 1000px) {
.hideForMobile {
  display: none;
}
.feature__buttons {
  margin-top: 1.5rem;
  text-align: center !important;
}
.feature_text {
  margin-top: 1.5rem;

}
.mr-20 {
  margin-right: 0;
}
}
@media (min-width: 1200px) {
  #home #header-content {
    // padding-top: 12.5rem;
}

}

.service-title {
  font-size: 16px;
}
@media (min-width: 992px) {
  #home #header-content {
    // padding-top: 11.5rem;
    // text-align: left;
}
}

@media (min-width: 768px) {
  #home #header-content {
    padding-top: 1rem;
}
}



@media (min-width: 1200px) {
  #header .text-container {
    margin-top: 5.375rem;
    margin-left: 1rem;
    margin-right: 2rem;
}
}


@media (min-width: 992px) {
  #header .text-container {
    margin-top: 3rem;
    margin-bottom: 0;
}
}


@media (min-width: 768px) {
  #header h1 {
    font-size: 3rem;
    line-height: 3.5rem;
}
}


#header h1 {
    margin-bottom: 1.125rem;
    font-size: 2.5rem;
    line-height: 3.125rem;
}
h1 {
    color: #393939;
    font: 700 3rem/3.5rem "Raleway", sans-serif;
}

// ------------------------------------------------------------------------------------------------------------------
#services {
  // padding-top: 7rem;
  // padding-bottom: 6.25rem;
  background: url(../../assets//landing-page/contact-background.jpg) center center no-repeat;
  background-size: cover;
  .container {
    max-width: 100rem;
  }
}
.gray-bg {
  background: #fbfbfb;
}
.pd-10 {
  padding: 10px;
}
.mt-40 {
  margin-top: 4rem;
}
.pb-130 {
  padding-bottom: 30px;
}
.pt-125 {
  padding-top: 100px;
}
.pt-30 {
  padding-top: 3rem;
}
.pm-30 {
  padding-bottom: 3rem;
}
.mx-width {
  width: 100%;
}

.single-service {
  background-color: #fff;
  border: 1px solid #eceff8;
  border-radius: 5px;
  padding: 25px 0 0 0;
  box-shadow: inset 2px 2px 5px #BABECC, inset -5px -5px 10px #FFF;
    transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.single-service .service-content {
  margin-top: 11px;
}
.mt-30 {
  margin-top: 30px;
}

.single-service .service-icon i {
  font-size: 60px;
  color: #00bfd8;
  line-height: 56px;
}

[class^="lni-"], [class*=" lni-"] {
  font-family: 'LineIcons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// -------------------------------------------------------------------------------------------------------------------
#about {
  box-shadow: inset 0px 0px 5px #BABECC, inset 0px 1px 0px #FFF;
  .row {
    margin-top: 20px;
  }
  
}
// -------------------------------------------------------------------------------------------------------------------
.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}


.cards-1 {
  padding-top: 4rem;
  padding-bottom: 1.625rem;
  text-align: center;
}

.cards-1 h2 {
  margin-bottom: 1rem;
}

.cards-1 .card {
  max-width: 21rem;
  margin-right: auto;
  margin-bottom: 4.5rem;
  margin-left: auto;
  padding: 3.25rem 2rem 2rem 2rem;
  border: 1px solid #c4d8dc;
  border-radius: 0.5rem;
  background: transparent;
}

.cards-1 .card-image {
  width: 6rem;
  height: 6rem;
  margin-right: auto;
  margin-bottom: 2rem;
  margin-left: auto;
}

.cards-1 .card-title {
  margin-bottom: 0.875rem;
}

.cards-1 .card-body {
  padding: 0;
}


@media (min-width: 992px) {
  .cards-1 .col-lg-12 div.card:nth-child(3n+2) {
    margin-right: 2rem;
    margin-left: 2rem;
  }

  .cards-1 .card {
    display: inline-block;
    max-width: 17.125rem;
    vertical-align: top;
  }
}

@media (min-width: 1200px) {
  .cards-1 .col-lg-12 div.card:nth-child(3n+2) {
    margin-right: 2.875rem;
    margin-left: 2.875rem;
  }

  .cards-1 .card {
    max-width: 21rem;
  }

}
// -----------------------------------------------------------------
.mr-20{
  margin-right: 20px;
}
 #features {
  padding: 50px 0;
  overflow: hidden;
  background: #f6fdfd;
  p, li {
    color: #000;
  }
  
 }
 .feature__buttons {
   margin-top: 1.5rem;
  //  text-align: center !important;
 }

 #px-banner {
  margin-top: 2em;
  border-radius: 6px;
  // background-image: url(images/px-bg.svg), url(images/px-banner.jpg);
  background-position: center center, center center;
  background-size: auto, cover;
  background-attachment: scroll, fixed;
  color: #fff;
  padding: 4em;
  font-weight: 400;
    visibility: visible;
    animation-delay: 0.2s;
    animation-name: fadeInUp;
    position: relative !important;
    background-size: cover !important;
    overflow: hidden !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    
 }



// ------------------------------------------------------------------------------------
.form-container {
  border-radius: 1.25rem;
  padding: 20px;
  margin-bottom: 40px;
}
#contact {
    padding-top: 1rem;
    padding-bottom: 0.5rem !important;
    background: url(../../assets/landing-page/testimonials-background.jpg) center center no-repeat;
    background-size: cover;
}
.form-group {
  position: relative;
  margin-bottom: 1.25rem;
}
input[type='text'],
input[type='number'],
textarea {
  font-size: 16px;
}

.form-control-input {
  display: block;
  width: 100%;
  border: 1px solid #c4d8dc;
  border-radius: 0.25rem;
  background-color: #fff;
  color: #626262;
  font: 400 16px "Raleway", sans-serif;
  transition: all 0.2s;
  -webkit-appearance: none;
  box-shadow: inset 2px 2px 5px #BABECC, inset -5px -5px 10px #FFF;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
}
.form-control-select {
  display: block;
    width: 100%;
    padding-top: 1.0625rem;
    padding-bottom: 0.0625rem;
    padding-left: 1.3125rem;
    border: 1px solid #c4d8dc;
    border-radius: 0.25rem;
    background-color: #fff;
    color: #626262;
    font: 400 16px "Raleway", sans-serif;
    transition: all 0.2s;
    -webkit-appearance: none;
}
.form-control-select {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  height: 3rem;
}

.form-control-input:focus, .form-control-select:focus, .form-control-textarea:focus {
  border: 1px solid #a1a1a1;
  outline: none;
}


.form-control-textarea.notEmpty + .label-control {
  top: 0.125rem;
  left: 0.6rem;
  opacity: 1;
  font-size: 0.75rem;
  font-weight: 700;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url(../../assets/landing-page/down-arrow.png);
  background-position: 96% 50%;
  background-repeat: no-repeat;
  outline: none;
}

.checkbox {
  font: 400 0.65rem/1.25rem "Raleway", sans-serif;
}

a {
  color: #626262;
  text-decoration: underline;
}

input[type='checkbox'] {
  // vertical-align: -15%;
  margin-right: 0.375rem;
}
.input-file {
  border: none !important;
  font-size: 14px;
    padding: 8px 0px;
    width: 100%;
    margin: 0 0 15px 0;
    max-width: 100%;
    resize: none;
}

.form-control-textarea {
  margin-top: 0px;
    margin-bottom: 0px;
    height: 115px;
    padding-top: 15px !important;
  display: block;
  width: 100%;
  height: 8rem;

  border: 1px solid #c4d8dc;
  border-radius: 0.25rem;
  background-color: #fff;
  color: #626262;
  font: 400 16px "Raleway", sans-serif;
  transition: all 0.2s;
  box-shadow: inset 2px 2px 5px #BABECC, inset -5px -5px 10px #FFF;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
}
.form-control-submit-button {
  display: inline-block;
  width: 100%;
  height: 3.125rem;
  border: 2px solid #00bfd8;
  border-radius: 1.5rem;
  background-color: #00bfd8;
  color: #fff;
  font: 700 0.75rem/1.75rem "Raleway", sans-serif;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: -5px -5px 20px #FFF, 5px 5px 20px #BABECC;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  font-weight: 600;
  &:hover {
    background-color: #fff;
    color: #039aea;
    box-shadow: -2px -2px 5px #FFF, 2px 2px 5px #BABECC;
  }
  &:active {
    box-shadow: inset 1px 1px 2px #BABECC, inset -1px -1px 2px #FFF;
  }

}

@media (min-width: 1200px) {
  .form-1 form {
    margin-right: 2rem;
    margin-left: 1rem;
}

  .form-1 .text-container {
    margin-right: 1.5rem;
    margin-left: 2rem;
}

}
#contact-form {
  padding-bottom: 3rem;
}


@media (min-width: 992px) {
  .form-1 {
    padding-top: 3rem;

}
.form-1 .text-container {
  margin-top: 1.5rem;
  margin-bottom: 0;
}
.form-1 .text-container {
  margin-bottom: 3.5rem;
}

}


.owl-carousel {
  visibility: visible;
    animation-delay: 0.2s;
    animation-name: fadeInUp;
}

  .category-input {
  // border: 1px solid #d1d1d1;
  font-size: 16px !important;
  padding: 8px 40px 8px 15px !important;
  font-size: 16px !important;
  box-shadow: inset 2px 2px 5px #BABECC, inset -5px -5px 10px #FFF;
    width: 100%;
    box-sizing: border-box;
    transition: all 0.2s ease-in-out;
    appearance: none;
    -webkit-appearance: none;
  // width: 100%;
  // margin: 0 0 15px 0;
  // max-width: 100%;
  // resize: none;
  }

// --------------------------------------------------------------------------------
.footer {
  // background-color: #fff;
  // padding-bottom: 0.5rem;
  padding-top: 3em;
}
.tooltip-inner {
  max-width: 100% !important;
}



  .last{
    text-align: center;
      // margin-bottom: 2.25rem;
  }

.footer .fa-stack {
  margin-bottom: 0.75rem;
  margin-right: 0.5rem;
  font-size: 1.5rem;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2em;
}

.copyright {
  padding-top: 1rem;
  padding-bottom: 0.375rem;
  text-align: center;
}
.copyright .p-small {
  padding-top: 1.375rem;
  border-top: 1px solid #c4d8dc;
  opacity: 0.7;
}
.custom-file-label {
  overflow: hidden !important;
  line-height: 1.6 !important;
  box-shadow: inset 2px 2px 5px #BABECC, inset -5px -5px 10px #FFF;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  appearance: none;
  -webkit-appearance: none;
}
.p-small {
  font: 400 0.75rem/1.25rem "Raleway", sans-serif;
}
h4 {
  color: #393939;
  font: 700 1.375rem/1.875rem "Raleway", sans-serif;
}

.footer h4 {
  margin-bottom: 1rem;
  
}
.footer p {
  color: #626262;
  font: 400 0.875rem/1.375rem "Raleway", sans-serif;
  
}


@media (min-width: 1200px) {
  .footer .footer-col {
    width: 90%;
}
}

