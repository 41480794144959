.sign-up {
  padding: 20px 40px;
  position: relative;
  // width: 380px;
  
    .title {
      margin: 10px 0;
    }
    .form-group {
      
    }
  }
  