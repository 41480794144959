.outerContainer {
    display: grid;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1A1A1D;
  }
  
  .chatRoom-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #FFFFFF;
    border-radius: 8px;
    height: 60%;
    width: auto;
  }
  
  @media (min-width: 320px) and (max-width: 480px) {
    .outerContainer {
      padding: 20px 20px 20px 20px;
    }
  
    .chatRoom-container {
      width: auto;
      height: 100%;
    }
  }
  
  @media (min-width: 720px) and (max-width: 1200px) {
    .chatRoom-container {
      width: 80%;
    }
  }
  