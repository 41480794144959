.sign-in {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 20px 40px;
    position: relative;
    // width: 380px;
    border-right-style: outset;
    .title {
       margin: 10px 0;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
    }
}