.infoBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #039aea !important;
    background: linear-gradient(-45deg, #039aea, #12c7f2) !important;
    border-radius: 4px 4px 0 0;
    height: 50px;
    width: 100%;
  }
  
  .leftInnerContainer {
    flex: 0.5;
    display: flex;
    align-items: center;
    margin-left: 5%;
    h3 {
      color: white;
      margin: 0;
      font-size: 14px;
      // text-transform: capitalize;
    }
    
  }
  
  .rightInnerContainer {
    display: flex;
    flex: 0.5;
    justify-content: flex-end;
    margin-right: 5%;
  }
  
  .onlineIcon {
    margin-right: 5%;
  }
  